// extracted by mini-css-extract-plugin
export var refWrapper = "ref-module--refWrapper--6j7Xn";
export var toc = "ref-module--toc--V+7yR";
export var categoryItems = "ref-module--categoryItems--TjfpS";
export var articleTitle = "ref-module--articleTitle--Kl2xS";
export var definitionItems = "ref-module--definitionItems--+E+J5";
export var categoryItem = "ref-module--categoryItem--E9KGz";
export var categoryTitle = "ref-module--categoryTitle--FUUcu";
export var definitionTitle = "ref-module--definitionTitle--DMRn8";
export var articleItems = "ref-module--articleItems--MP+Yr";
export var articleItem = "ref-module--articleItem--QGvz+";
export var definitionItem = "ref-module--definitionItem--bPr6n";
export var content = "ref-module--content--LjgvZ";
export var cite = "ref-module--cite--coF2q";
export var definitionContent = "ref-module--definitionContent--L4HM+";